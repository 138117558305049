import React, {useEffect,useState} from 'react';
import { Menu } from "antd";
import { AppstoreOutlined, NotificationOutlined, EditOutlined } from '@ant-design/icons';
import { routes } from "../../utilities/routes";
import { useNavigate, useLocation } from "react-router-dom"

function MenuComponent(props) {
    let navigate = useNavigate();
    const location = useLocation();
    const [activePath, setActivePath] = useState("")

    useEffect(()=>{
        setActivePath(location.pathname)
    },[location.pathname])

    function onTabChanged(e) {
        navigate(e.key)
    }

    return (
        <Menu onClick={onTabChanged} theme="light" mode="inline" defaultSelectedKeys={[location.pathname]}>
            {/* <Menu.Item key={routes.dashboard.url} icon={<AppstoreOutlined />}>
                Dashboard
            </Menu.Item> */}
            <Menu.Item key={routes.session.url} icon={<EditOutlined />}>
                Session
            </Menu.Item>
            <Menu.Item key={routes.presenter.url} icon={<NotificationOutlined />}>
                Presenter Library
            </Menu.Item>
        </Menu>
    );
}

export default MenuComponent;