import { Form, Input, Button, DatePicker, TimePicker } from "antd"

export default function LoginForm({ onFinish, onFinishFail, isLoading, controller }) {
    return (<>
        <Form size="default" onFinish={controller}>
            <div className="col-12">
                <Form.Item
                    name="title"
                    rules={[{ required: true, message: 'Please input title' }]}
                >
                    <Input placeholder="Title" />
                </Form.Item>
            </div>

            <div className="row col-12">
                <div className="col-6">
                    <Form.Item
                        name="presenterName"
                        rules={[{ required: true, message: 'Please input Presenter Name' }]}
                    >
                        <Input placeholder="Presenter Name" />
                    </Form.Item>
                </div>
                <div className="col-6">
                    <Form.Item
                        name="supervisorName"
                        rules={[{ required: true, message: 'Please input Supervisor Name' }]}
                    >
                        <Input placeholder="Supervisor Name" />
                    </Form.Item>
                </div>
            </div>
            <div className="row col-12">
                <div className="col-4">
                    <Form.Item
                        name="duration"
                        rules={[{ required: true, message: 'Please input duration' }]}
                    >
                        <Input placeholder="Duration" />
                    </Form.Item>
                </div>
                <div className="col-4">
                    <Form.Item
                        name="presenterId"
                        rules={[{ required: true, message: 'Please input Presenter Id' }]}
                    >
                        <Input placeholder="Presenter Id" />
                    </Form.Item>
                </div>
                <div className="col-4">
                    <Form.Item
                        name="supervisorId"
                        rules={[{ required: true, message: 'Please input Supervisor Id' }]}
                    >
                        <Input placeholder="Supervisor Id" />
                    </Form.Item>
                </div>
            </div>
            <div className="row col-12">
                <div className="col-6">
                    <Form.Item
                        name="startDate"
                        rules={[{ required: true, message: 'Please input start date' }]}
                    >
                        <DatePicker placeholder="Start Date" />
                    </Form.Item>
                </div>
                <div className="col-6">
                    <Form.Item
                        name="startTime"
                        rules={[{ required: true, message: 'Please input start time' }]}
                    >
                        <TimePicker placeholder="Start Time" />
                    </Form.Item>
                </div>
            </div>
            <div className="col-12">
                <Form.Item
                    name="description"
                    rules={[{ required: true, message: 'Please input description' }]}
                >
                    <Input.TextArea placeholder="Description" />
                </Form.Item>
            </div>
            <Button type="primary" loading={isLoading} className="mt-3" htmlType="submit">
                Create
            </Button>
        </Form>
    </>)
}