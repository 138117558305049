import React, { useEffect } from 'react';
import { Layout, Typography } from 'antd';
import { Outlet } from "react-router-dom";
import FooterComponent from '../components/footer';
import HeaderComponent from '../components/header';
import MenuComponent from '../components/menu';
import { getSessionStorageToken } from '../utilities/common';
import { routes } from '../utilities/routes';
import { useNavigate } from 'react-router';

const { Content, Sider } = Layout;
const { Title } = Typography;

function DashboardLayout(props) {
    let isAuth = getSessionStorageToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth) {
            navigate(routes.login.url);
        }
    }, [])

    return isAuth ? (
        <>
            <Layout style={{ minHeight: "100vh", backgroundColor: "red" }}>
                <Sider style={{ position: "fixed", overflow: 'auto', height: '100vh', left: 0 }} theme="light">
                    <div className="logo">
                        <Title level={3}>V Meeting</Title>
                    </div>
                    <MenuComponent />
                </Sider>
                <Layout className="site-layout" style={{ marginLeft: 200 }}>
                    <HeaderComponent />
                    <Content className="site-layout-background mt-5">
                        <div className="main-content">
                            <Outlet />
                        </div>
                    </Content>
                    <FooterComponent />
                </Layout>
            </Layout>
        </>
    ) : <></>;
}

export default DashboardLayout;