export const routes = {
    dashboard: {
        url: "/admin/dashboard",
        key: "dashboard"
    },
    session: {
        url: "/admin/session",
        key: "session"
    },
    presenter: {
        url: "/admin/presenter",
        key: "presenter"
    },
    login: {
        url: "/login",
        key: "login"
    },
    sessionDetails: {
        url: "/admin/sessionDetails",
        key: "sessionDetails"
    },
}