import { Form, Input, Button } from "antd"

export default function LoginForm({ onFinish, onFinishFail, isLoading }) {
    return (<>
        <Form size="large" onFinish={onFinish} onFinishFailed={onFinishFail}>
            <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password placeholder="Password" />
            </Form.Item>
            <Button type="primary" loading={isLoading} className="mt-3" htmlType="submit">
                Submit
            </Button>
        </Form>
    </>)
}