import React, { useEffect, useState } from 'react';
import APIrequest from '../services';
import ApiEndPoints from '../utilities/apiEndPoints';
import logger from '../utilities/logger';
import DataTable from '../components/data-table';
import { Descriptions, PageHeader, Typography } from 'antd';
import { useParams, useNavigate, br } from 'react-router-dom';
import { routes } from '../utilities/routes';

const { Title } = Typography;

const attendeeColumn = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name"
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name"
    },
    {
        title: "Attendee Link",
        dataIndex: "attendeeLink",
        key: "attendeeLink"
    },
]

function SessionDetails(props) {
    /**
     * Initializations
     */
    const params = useParams();
    // const context = useRoutes();
    const navigate = useNavigate();
    const [state, setState] = useState({
        sessionData: {}
    })
    /**
     * Hook to initiate function on component gets mounted
     */
    useEffect(() => {
        getSessionById();
    }, [state.sessionData.id])

    /**
     * Function to get session by Session Id
     */
    const getSessionById = async () => {
        try {
            const payload = {
                ...ApiEndPoints.sessionById(params.slug)
            }
            let res = await APIrequest(payload);
            if (res.success) {
                setState({
                    sessionData: res.data
                })
            } else {
                throw res.message;
            }
        }
        catch (error) {
            logger(error);
        }
    }

    const { title, actualDuration, description, presenterLink, presenterName, supervisorName, supposedDuration, status, supervisorLink } = state.sessionData;
    /**
     * Render Part of the component
     */
    return (
        <div className="row col-12">
            <PageHeader title="Session Detail" onBack={() => navigate(routes.session.url)}/>
            <Descriptions  bordered column={{ xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
                <Descriptions.Item label="Title">{title}</Descriptions.Item>
                <Descriptions.Item label="Supervisor Name">{supervisorName}</Descriptions.Item>
                <Descriptions.Item label="Presenter Name">{presenterName}</Descriptions.Item>
                <Descriptions.Item label="Supposed Duration">{supposedDuration}</Descriptions.Item>
                <Descriptions.Item label="Actual Duration">{actualDuration}</Descriptions.Item>
                <Descriptions.Item label="Description">{description}</Descriptions.Item>
                <Descriptions.Item label="Presenter Link">{presenterLink}</Descriptions.Item>
                <Descriptions.Item label="Supervisor Link">{supervisorLink}</Descriptions.Item>
                <Descriptions.Item label="Status">{status}</Descriptions.Item>
            </Descriptions>
            <div className="col-12">
                <DataTable isPagination={false} title={() => 'Attendee'} columns={attendeeColumn} data={state.sessionData.SessionAttendees} />
            </div>
        </div>
    )
}
export default SessionDetails;