import React, { useState, useEffect } from 'react';
import DataTable from "../components/data-table";
import { Button, Typography, Dropdown, Menu } from "antd";
import APIrequest from '../services';
import ApiEndPoints from '../utilities/apiEndPoints';
import SessionModal from "../components/modals/sessionModal";
import { getFormattedDateAndTime } from "../components/common";
import { MoreOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router';
import { routes } from '../utilities/routes';
import modalNotification from '../utilities/notifications';

const { Title } = Typography;

function PresenterLibrary(props) {

    const menu = (record) => {
        return <Menu>
            <Menu.Item key="delete" onClick={(e) => deleteSession(e, record.id)}>
                Delete
            </Menu.Item>
            <Menu.Item key="view" onClick={(e) => onView(e, record.id)}>
                View
            </Menu.Item>
        </Menu>
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title"
        },
        {
            title: "Presenter Name",
            dataIndex: "presenterName",
            key: "presenterName"
        },
        {
            title: "Supervisor Name",
            dataIndex: "supervisorName",
            key: "supervisorName"
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description"
        },
        {
            title: "Start Time",
            dataIndex: "startDateTime",
            key: "startDateTime",
            render: getFormattedDateAndTime
        },
        {
            title: "End Time",
            dataIndex: "endDateTime",
            key: "endDateTime",
            render: getFormattedDateAndTime
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Dropdown overlay={() => menu(record)} trigger={['click']}>
                    <MoreOutlined />
                </Dropdown>
            ),
        },
    ];

    const [visible, setVisible] = useState(false);
    const [state, setState] = useState({ data: [], loading: true, totalSize: 0 });
    const [pagination, setPagination] = useState({
        limit: 10,
        offset: 0,
        page: 1
    });
    const navigate = useNavigate();

    useEffect(() => {
        getSessions();
    }, [pagination]);

    async function getSessions(limit = pagination.limit, offset = pagination.offset) {
        try {
            setState({
                data: [...state.data],
                loading: true,
                totalSize: state.totalSize
            })
            let queryParams = {
                limit,
                offset: (pagination.page - 1) * limit
            }
            let payload = {
                ...ApiEndPoints.getPresenter,
                queryParams
            }
            let res = await APIrequest(payload);
            setState({ data: res.data.rows, loading: false, totalSize: res.data.total });
        } catch (err) {
            setState({ data: [], loading: false })
        }
    }

    async function deleteSession(e, id) {
        try {
            let payload = {
                ...ApiEndPoints.deleteSession(id)
            }
            await APIrequest(payload);
            getSessions();
            modalNotification({
                type: "success",
                description: 'Session deleted successfully',
                message: "Session deleted"
            });
        } catch (err) {
            console.log(err);
        }
    }

    function onOpenModal() {
        setVisible(true);
    }

    function onCancelModal() {
        setVisible(false)
    }

    function onPageChange(page) {
        setPagination({
            ...pagination,
            page: page,
        })
    }

    function onView(e, id) {
        navigate(`${routes.sessionDetails.url}/${id}`)
    }

    async function onCreateSession(e) {
        let date = e.startDate.format("YYYY-MM-DD");
        let time = e.startTime.format("hh:mm:ss");
        let startTime = date + ' ' + time;
        delete e["startDate"];
        delete e["startTime"];
        e.startTime = startTime;
        await createSession(e);
    }

    async function createSession(bodyData) {
        try {
            let payload = {
                ...ApiEndPoints.createSession,
                bodyData
            }
            await APIrequest(payload);
            modalNotification({
                type: "success",
                description: 'Session created successfully',
                message: "Session deleted"

            });
            getSessions(pagination.limit, pagination.offset);
            setVisible(false);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="flex-fill">
            {/* <SessionModal controller={onCreateSession} onCancel={onCancelModal} visible={visible} />
            <div className="content-header d-flex flex-row justify-content-between pt-1 pb-3 pl-1 pr-1">
                <Title level={3}>Sessions</Title>
                <Button onClick={onOpenModal}>
                    Add Session
                </Button>
            </div>
            <DataTable page={pagination.page} onPageChange={onPageChange} totalSize={state.totalSize} loading={state.loading} columns={columns} data={state.data} /> */}
        </div>
    );
}

export default PresenterLibrary;