import React, { useState, useEffect } from 'react'
import { Spin, Select, Avatar } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { currentTimeStamp, getFormatDateTime } from '../../utilities/common'

export function PhoneNumberComponent({ phoneNumberCountryCode, phoneNumber }) {
  return <> {`${phoneNumberCountryCode} ${phoneNumber}`
  }</>
}

PhoneNumberComponent.propTypes = {
  phoneNumberCountryCode: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired
}

export function EmailComponent({ emailId }) {
  return <> {`${emailId}`
  }</>
}

EmailComponent.propTypes = {
  emailId: PropTypes.string.isRequired
}

export function ImageComponent({ src, imgprops }) {
  return (
    <a data-fancybox={`gallry_${currentTimeStamp()}_${src}`} href={src}>
      <img src={src} {...imgprops} alt={src} />
    </a>
  )
}

ImageComponent.propTypes = {
  src: PropTypes.string,
  imgprops: PropTypes.object
}
export function NameComponentWithImage({ firstName, lastName, image, userName = null }) {
  return (
    <>
      <div className='d-flex align-items-center avatarSec'>
        <Avatar size={40} src={image} />
        <div>
          <p>
            {firstName} {lastName}
          </p>
          <small>
            {userName}
          </small>
        </div>
      </div>
    </>
  )
}
NameComponentWithImage.propTypes = {
  firstName: PropTypes.string.isRequired
}
export function VideoComponent({ src, videoprops, imgSrc }) {
  return (
    <a
      className='fancybox videoThumb'
      data-fancybox={`gallry_${currentTimeStamp()}_${src}`}
      href={src}
    >
      <img src={imgSrc} {...videoprops} alt={src} />
      <span className='icon-video-button play_icon'></span>
    </a>
  )
}

VideoComponent.propTypes = {
  src: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  videoprops: PropTypes.object
}

export function AudioComponent({ src }) {
  return (
    <audio controls>
      <source src={src} />
    </audio>
  )
}
AudioComponent.propTypes = {
  src: PropTypes.string.isRequired
}

AudioComponent.propTypes = {
  src: PropTypes.string.isRequired
}

export function StatusBadgeComponent({ status }) {
  if (!['active', 'inactive'].includes(status)) {
    return (
      <span className={'badge badge-pill align-middle ml-2'}>{status}</span>
    )
  }
  status = status === 'active'
  return (
    <span
      className={`badge badge-pill ${status ? 'badge-success' : 'badge-danger'
        } align-middle ml-2`}
    >
      {status ? 'Active' : 'Inactive'}
    </span>
  )
}

StatusBadgeComponent.propTypes = {
  status: PropTypes.string.isRequired
}

export function UsernameComponent({ username }) {
  return (
    <>
      <img alt='mail-icon' src='/images/user-icon.png' />{' '}
      <span>{username} </span>
    </>
  )
}

UsernameComponent.propTypes = {
  username: PropTypes.any.isRequired
}

export function GoToUserDetailPage({ data, id, state = {}, classProps = '' }) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/user-detail/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToUserDetailPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function GoToViewTracksPage({ data, id, state = {}, classProps = '' }) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/view-track/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToViewTracksPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}
export function GoToViewContestPage({ data, id, state = {}, classProps = '' }) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/contest/${id}/participants`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToViewContestPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function GoToViewCollectionTracksPage({
  data,
  id,
  state = {},
  classProps = ''
}) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/view-collection-track/${id}`,
          state: state
        }}
      >
        <span className="text-capitalize" >  {data}</span>
      </Link>
    </>
  )
}

GoToViewCollectionTracksPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}
export function GoToViewTodayEventDetail({
  data,
  id,
  state = {},
  classProps = ''
}) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/event/today-event/event-detail/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToViewTodayEventDetail.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function GoToViewCollectionVideosPage({
  data,
  id,
  state = {},
  classProps = ''
}) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/view-collection-video/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToViewCollectionVideosPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function GoToVideoDetailPage({ data, id, state = {}, classProps = '' }) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/video-detail/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToVideoDetailPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function ReportedByCountShow({ data, row, showList }) {
  return (
    <>
      <a
        href='/'
        className='ml-5'
        onClick={(e) => {
          e.preventDefault()
          showList(row)
        }}
      >
        {data}
      </a>
    </>
  )
}

ReportedByCountShow.propTypes = {
  data: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  showList: PropTypes.func.isRequired
}

export function ReadMoreTextShow({ data, type, showMoreText, t }) {
  if ([undefined, null, false].includes(data)) {
    return <></>
  }
  if (data.length < 80) {
    return <> {data}</>
  }

  return (
    <>
      {data.substring(0, 80)}{' '}
      <a
        href='/'
        className='theme-color ml-1'
        onClick={(e) => {
          e.preventDefault()
          showMoreText({ type, data })
        }}
      >
        {' '}
        {t('common.readMore')}...
      </a>
    </>
  )
}

ReadMoreTextShow.propTypes = {
  data: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  showMoreText: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export function DateComponent({ date }) {
  return <> {date}</>
}

DateComponent.propTypes = {
  date: PropTypes.string.isRequired
}

export function IntervalInSec({ time }) {
  return <> {time}</>
}

IntervalInSec.propTypes = {
  time: PropTypes.number.isRequired
}

export function LoadingSpinner() {
  return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
}

export function GlobalLoader() {
  return (
    <div className='listloader text-center'>
      <div className='Listpreloader'>
        <div className='spinner'>
          <div className='dot1'></div>
          <div className='dot2'></div>
        </div>
      </div>
    </div>
  )
}

export function WinnerTypesControlledSelect({
  cell,
  row,
  contestData,
  onChange
}) {
  const [statusValue, setStatusValue] = useState(cell)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(
    ['gold', 'silver', 'bronze'].includes(cell)
  )

  useEffect(() => {
    setStatusValue(cell)
    setIsDisabled(['gold', 'silver', 'bronze'].includes(cell))
  }, [cell, row])

  const resHandleChange = (val) => {
    setIsLoading(false)
    setStatusValue(val)
    setIsDisabled(true)
  }

  const handleChange = (val) => {
    setIsLoading(true)
    onChange(val, row, contestData, resHandleChange)
  }

  return (
    <>
      {' '}
      {
        cell ? (
          <span className='text-capitalize'>{cell}</span>
        ) : (
          <Select
            defaultValue={cell}
            disabled={isDisabled}
            value={statusValue}
            onChange={handleChange}
            loading={isLoading}
            placeholder='Is Winner'
          >
            {contestData.ContestWinnerTypes.length
              ? contestData.ContestWinnerTypes.map((data, index) => {
                return (
                  <Select.Option
                    key={`${index}_${data.id}`}
                    className='text-capitalize'
                    value={data.id}
                  >
                    <span className='text-capitalize'>{data.winnerType}</span>{' '}
                  </Select.Option>
                )
              })
              : '-'}
          </Select>
        )
      }
    </>
  )
}

WinnerTypesControlledSelect.propTypes = {
  cell: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  contestData: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}


export function getFormattedDateAndTime(value, row, index) {
  return <span>{getFormatDateTime(value)}</span>
}