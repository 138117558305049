import { Outlet } from "react-router-dom";
import { getSessionStorageToken } from '../utilities/common';
import { routes } from '../utilities/routes';
import { useNavigate } from 'react-router';
import { useEffect } from "react";

export default function PublicLayout() {
    let isAuth = getSessionStorageToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth) {
            navigate(routes.dashboard.url);
        }
    }, [])
    return !isAuth ? (
        <div style={{ minHeight: "100vh" }}>
            <Outlet />
        </div>
    ) : <></>;
}