let ApiEndPoints = {
    adminLogin: {
        url: '/api/account/login',
        method: 'POST'
    },
    getSession:{
        url:"/api/session",
        method:'GET'
    },
    sessionById: (id) => ({
        url: `/api/session/${id}`,
        method: 'GET'
    }),
    deleteSession: (id) => ({
        url: `/api/session/${id}`,
        method: 'DELETE'
    }),
    createSession:{
        url:"/api/session",
        method:"POST"
    },
    getPresenter:{
        url:"/api/presenter-library/folder-list",
        method:"GET"
    }
}
export default ApiEndPoints