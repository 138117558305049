import React from 'react';
import { Layout } from "antd";

const { Footer } = Layout;

function FooterComponent(props) {
    return (
        <>
            <Footer>@2021 V-Meeting</Footer>
        </>
    );
}

export default FooterComponent;