import React from 'react';
import { Table, Pagination } from 'antd';

function DataTable(props) {
    const { columns, data, loading, isPagination = true, title, totalSize = 0, currentSize = 10, page, onPageChange } = props;

    function pageChange(e) {
        onPageChange(e);
    }
    return (
        <>
            <Table title={title} rowKey="id" pagination={config => console.log(config)} loading={loading} columns={columns} dataSource={data} pagination={false} />
            <div className="col-12 d-flex flex-row-reverse mt-3">
                {isPagination ? <Pagination
                    current={page}
                    pageSize={currentSize}
                    total={totalSize}
                    onChange={pageChange}
                /> : ""}
            </div>
        </>
    );
}

export default DataTable;