import LoginForm from '../components/forms/LoginForm';
import { Typography } from "antd";
import APIrequest from "../services";
import ApiEndPoints from "../utilities/apiEndPoints";
import logger from "../utilities/logger";
import { currentTimeStamp, setSessionStorageToken, getSessionStorageToken } from '../utilities/common'
import modalNotification from "../utilities/notifications";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { routes } from "../utilities/routes";

const { Title } = Typography;

export default function Login() {
  const [state, setState] = useState({
    isLoading: false
  })

  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    try {
      setState({
        ...state,
        isLoading: true
      });
      let payLoad = {
        ...ApiEndPoints.adminLogin,
        bodyData: {
          email: values.email,
          password: values.password,
          deviceType: 'Web',
          deviceId: `${currentTimeStamp()}`
        }
      };
      let res = await APIrequest(payLoad);
      let msg = {};

      if (res && res.data && res.data.token) {
        setSessionStorageToken(res.data.token);
        console.log(getSessionStorageToken());
        msg.type = "success";
        msg.message = "Welcome back";
        msg.description = res.message;
        navigate(routes.session.url);
      } else {
        msg.type = "error";
        msg.message = "Something went wrong";
        msg.description = "";
      }

      modalNotification(msg)
      setState({
        isLoading: false,
      });
    } catch (error) {
      setState({
        isLoading: false,
      });
      logger(error)
    }

  }
  const onFinishFail = (err) => {
    setState({
      isLoading: false
    });
    logger(err)
  }
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <div className="col-4 pr-5 pl-5">
        <Title className="mb-4" level={3}>Login</Title>
        <div>
          <LoginForm onFinish={handleFormSubmit} onFinishFail={onFinishFail} isLoading={state.isLoading} />
        </div>
      </div>
    </div>
  );
}
