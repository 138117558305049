import React from 'react';
import { Layout } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { removeSessionStorageToken } from '../../utilities/common';

const { Header } = Layout;

function HeaderComponent(props) {

    function onLogout() {
        removeSessionStorageToken();
    }

    return (
        <>
            <Header className="header-layout-background pr-2" style={{ position: "fixed", right: 0, left: 200, zIndex:1000 }}>
                <div onClick={onLogout}>
                    <LogoutOutlined />
                </div>
            </Header>
        </>
    );
}

export default HeaderComponent;