import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    
}

export const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setUser:(state, action)=>{
            return {
                ...action.payload
            }
        },
        removeUser:(state)=>{
            return initialState;
        }
    }
})

export const {setUser, removeUser} = userSlice.actions;
export default userSlice.reducer;