const config = {
  // NODE_ENV: process.env.NODE_ENV,
  NAME_KEY: process.env.REACT_APP_NAME_KEY,
  // NAME_TITLE: process.env.REACT_APP_NAME_TITLE,
  // DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE,
  // BASE_URL: process.env.REACT_APP_BASE_URL,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  // BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
  // SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  // IMAGE_UPLOAD_SIZE_LIMIT: Number(process.env.REACT_APP_IMAGE_UPLOAD_SIZE_LIMIT),
  // AUDIO_UPLOAD_SIZE_LIMIT: Number(process.env.REACT_APP_AUDIO_UPLOAD_SIZE_LIMIT),
  // VIDEO_UPLOAD_SIZE_LIMIT: Number(process.env.REACT_APP_VIDEO_UPLOAD_SIZE_LIMIT),
  // AUDIO_UPLOAD_LENGTH_LIMIT: Number(process.env.REACT_APP_AUDIO_UPLOAD_LENGTH_LIMIT),
  // AUDIO_UPLOAD_LENGTH_MINIMUM: Number(process.env.REACT_APP_AUDIO_UPLOAD_LENGTH_MINIMUM),
  // VIDEO_UPLOAD_LENGTH_LIMIT: Number(process.env.REACT_APP_VIDEO_UPLOAD_LENGTH_LIMIT),
  // VIDEO_UPLOAD_LENGTH_MINIMUM: Number(process.env.REACT_APP_VIDEO_UPLOAD_LENGTH_MINIMUM)
}

export default config;
