import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import Login from '../pages/Login';
import PublicLayout from "../layouts/PublicLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import NotFound from '../pages/Page404';
import requireAuth from '../components/hoc/requireAuth';
import Dashboard from '../pages/Dashboard';
import Poll from '../pages/Poll';
import Session from '../pages/Session';
import { routes } from "../utilities/routes";
import SessionDetails from '../pages/SessionDetails';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        // { path: routes.dashboard.key, element: <Dashboard /> },
        { path: routes.session.key, element: <Session /> },
        { path: "sessionDetails", element: <Outlet/>, children: [{ path: ":slug", element: <SessionDetails /> }] },
        { path: routes.presenter.key, element: <Poll /> },
      ]
    },
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
