import React from 'react';
import { Modal, Button } from 'antd';
import SessionModal from "../forms/sessionForm";

function Session(props) {
    const { visible, onCancel, controller } = props;
    return (
        <Modal
            title="Create Session"
            centered
            visible={visible}
            footer={false}
            // onOk={() => this.setModal2Visible(false)}
            onCancel={onCancel}
        >
            <SessionModal controller={controller} />
        </Modal>
    );
}

export default Session;