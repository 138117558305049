import React from 'react'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import querystring from 'querystring'
import {
  PhoneNumberComponent,
  ImageComponent,
  EmailComponent,
  AudioComponent,
  VideoComponent,
  DateComponent,
  GoToUserDetailPage,
  ReadMoreTextShow,
  GoToViewTracksPage,
  GoToViewContestPage,
  GoToVideoDetailPage,
  ReportedByCountShow,
  IntervalInSec,
  GoToViewCollectionTracksPage,
  GoToViewCollectionVideosPage,
  WinnerTypesControlledSelect,
  NameComponentWithImage
} from '../components/common'
import config from '../config'
import logger from './logger'
import APIrequest from '../services'
import ApiEndPoints from '../utilities/apiEndPoints'

export const setSessionStorageToken = (token) => {
  sessionStorage.setItem(
    `${config.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${config.NAME_KEY}-token`).toString()
  )
}

export const handlePrizeTitileChange = async function (val) {
  let result = 0
  const payload = {
    ...ApiEndPoints.getShopifyProducts,
    queryParams: {
      title: val
    }
  }
  const res = await APIrequest(payload)

  result = res.data.length ? res.data[0].id : 0
  return result
}

export const checkUserPermission = (userData, moduleKey) => {
  let isEdit = false
  isEdit = userData.userType === 'admin' ? true : false
  if (userData.permissions) {
    let moduleKeyObj = userData.permissions.find((item) => {
      return item.moduleKey === moduleKey
    })
    if (moduleKeyObj && moduleKeyObj.permission === 'edit') {
      isEdit = true
    }
  }
  return isEdit
}

export const getSessionStorageToken = () => {
  const ciphertext = sessionStorage.getItem(`${config.NAME_KEY}:token`)
  if (ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, `${config.NAME_KEY}-token`)
    return bytes.toString(CryptoJS.enc.Utf8)
  }
  return false
}

export const removeSessionStorageToken = () => {
  sessionStorage.removeItem(`${config.NAME_KEY}:token`)
  window.location.href = '/login'
}

export const getLocalStorageLanguage = () => {
  const language = localStorage.getItem(`${config.NAME_KEY}:language`)
  if (language) {
    return ['en', 'hi'].includes(language) ? language : config.DEFAULT_LANGUAGE
  }
  return config.DEFAULT_LANGUAGE
}

/**
 * Formatter for Data Tables
 */
export const phoneNumberFormatter = (cell, row) => {
  return (
    <PhoneNumberComponent
      phoneNumberCountryCode={row.phoneNumberCountryCode}
      phoneNumber={row.phoneNumber}
    />
  )
}

export const userPhoneNumberFormatter = (cell, row) => {
  return (
    <PhoneNumberComponent
      phoneNumberCountryCode={row.User.phoneNumberCountryCode}
      phoneNumber={row.User.phoneNumber}
    />
  )
}

export const emailFormatter = (cell, row) => {
  return <EmailComponent emailId={cell} />
}

export const imageFormatter = (cell, row) => {
  return (
    <div className='user_img'>
      <ImageComponent src={cell} />
    </div>
  )
}
export const nameWithImageFormatter = (cell, row) => {
  return (
    <NameComponentWithImage
      firstName={row.firstName}
      lastName={row.lastName}
      image={row.profilePictureThumbUrl}
      userName={`@${row.username}`}
    />
  )
}
export const videoFormatter = (cell, row) => {
  return (
    <div className='user_img'>
      <VideoComponent src={cell} imgSrc={row.mediaFileThumbUrl} />
    </div>
  )
}

export const audioFormatter = (cell, row) => {
  return <AudioComponent src={cell} />
}

export const winnerTypeStatusFormatter = (cell, row, contestData, onChange) => {
  return ['gold', 'silver', 'bronze'].includes(cell) ? (
    <span className='text-capitalize'>{cell}</span>
  ) : (
    <WinnerTypesControlledSelect
      cell={cell}
      row={row}
      contestData={contestData}
      onChange={onChange}
    />
  )
}

export const dateFormatter = (cell, row) => {
  return <DateComponent date={showDateInBrowser(cell)} />
}

export const OnlyDateFormatter = (cell, row) => {
  return <DateComponent date={showDateOnlyInBrowser(cell)} />
}

export const intervalFormatter = (cell, row) => {
  return <IntervalInSec time={Number(cell)} />
}

export const goToUserDetail = (cell, row, id, state = {}, classProps = '') => {
  return (
    <GoToUserDetailPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const readMoreText = (cell, row, type, showMoreText, t) => {
  return (
    <ReadMoreTextShow
      data={cell}
      type={type}
      showMoreText={showMoreText}
      t={t}
    />
  )
}

export const serialNumberFormatter = (rowIndex, currentPage, dataPerPage) => {
  return rowIndex + dataPerPage * (currentPage - 1) + 1 || rowIndex
}

export const goToViewTracks = (cell, row, id, state = {}, classProps = '') => {
  return (
    <GoToViewTracksPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}
export const goToViewContest = (cell, row, id, state = {}, classProps = '') => {
  return (
    <GoToViewContestPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const goToViewCollectionTracks = (
  cell,
  row,
  id,
  state = {},
  classProps = ''
) => {
  return (
    <GoToViewCollectionTracksPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const goToViewCollectionVideos = (
  cell,
  row,
  id,
  state = {},
  classProps = ''
) => {
  return (
    <GoToViewCollectionVideosPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const goToVideoDetail = (cell, row, id, state = {}, classProps = '') => {
  return (
    <GoToVideoDetailPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const reportedByCount = (cell, row, showList) => {
  return <ReportedByCountShow data={cell} row={row} showList={showList} />
}

/**
 * Toggle Class
 */

export const tagClassToggle = (tag, className) => {
  const element = document.getElementsByTagName(tag)[0]

  if (element.classList) {
    element.classList.toggle(className)
  } else {
    const classes = element.className.split(' ')
    const i = classes.indexOf(className)

    if (i >= 0) classes.splice(i, 1)
    else classes.push(className)
    element.className = classes.join(' ')
  }
}

/**
 * Date Picker Range class Add
 */

export const onOpenDateRange = (status) => {
  if (status) {}
}

/**
 * Date Time
 */
export const dateFormatDMY = 'DD-MM-YYYY'

export const showDateInBrowser = (data) => {
  if (
    moment(
      moment(data).format('DD/MM/YYYY hh:mm A'),
      'DD/MM/YYYY hh:mm A',
      true
    ).isValid()
  ) {
    return moment(data).format('DD/MM/YYYY hh:mm A')
  } else {
    return '-'
  }
}
export const showDateOnlyInBrowser = (data) => {
  if (moment(moment(data).format('DD/MM/YYYY'), 'DD/MM/YYYY', true).isValid()) {
    return moment(data).format('DD/MM/YYYY')
  } else {
    return '-'
  }
}

export const toSendDateInApi = (data) => {
  return moment(data).format('YYYY-MM-DD')
}

export const currentTimeStamp = () => new Date().getTime()

/**
 * Dashboard Number Format
 */

export const numberFormatter = (num) => {
  num = Number(num)
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  }
  return num || 0
}

/**
 * File Size
 */

export const fileSizeLimitCheck = (fileSize, fileType) => {
  const res = {
    success: true
  }

  fileSize = fileSize / 1024 / 1024 // In MB

  if (fileType === 'image') {
    if (fileSize > config.IMAGE_UPLOAD_SIZE_LIMIT) {
      res.success = false
      res.limit = config.IMAGE_UPLOAD_SIZE_LIMIT
    }
  } else if (fileType === 'audio') {
    if (fileSize > config.AUDIO_UPLOAD_SIZE_LIMIT) {
      res.success = false
      res.limit = config.AUDIO_UPLOAD_SIZE_LIMIT
    }
  } else if (fileType === 'video') {
    if (fileSize > config.VIDEO_UPLOAD_SIZE_LIMIT) {
      res.success = false
      res.limit = config.VIDEO_UPLOAD_SIZE_LIMIT
    }
  } else {
    res.success = false
    res.limit = 100
  }

  return res
}

/**
 * Audio File Length
 */

export const checkAudioFileLength = (length) => {
  const res = {
    success: true
  }

  if (
    length > config.AUDIO_UPLOAD_LENGTH_LIMIT ||
    length < config.AUDIO_UPLOAD_LENGTH_MINIMUM
  ) {
    res.success = false
    res.limit = {
      minLength: config.AUDIO_UPLOAD_LENGTH_MINIMUM,
      maxLength: config.AUDIO_UPLOAD_LENGTH_LIMIT
    }
  }

  return res
}

/**
 * File Reader
 */

export const audioFileReader = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = (e) => {
      // https://ourcodeworld.com/articles/read/1036/how-to-retrieve-the-duration-of-a-mp3-wav-audio-file-in-the-browser-with-javascript
      const audio = document.createElement('audio')
      // audio.src = e.target.result
      audio.src = URL.createObjectURL(file) // https://medium.com/@dineshvasudevan/duration-of-an-audio-file-via-javascript-e8d78f26b15f
      audio.addEventListener(
        'loadedmetadata',
        () => {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          const duration = audio.duration

          // example 12.3234 seconds
          // console.log('The duration of the song is of: ' + duration + ' seconds')
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
          resolve(parseInt(duration))
        },
        false
      )
    }
    reader.onerror = (error) => reject(error)
  })
}

export const audioURLReader = (url) => {
  return new Promise((resolve, reject) => {
    // https://ourcodeworld.com/articles/read/1036/how-to-retrieve-the-duration-of-a-mp3-wav-audio-file-in-the-browser-with-javascript

    // Create a non-dom allocated Audio element
    const audio = document.createElement('audio')

    // Define the URL of the MP3 audio file
    audio.src = url

    // Once the metadata has been loaded, display the duration in the console
    audio.addEventListener(
      'loadedmetadata',
      function () {
        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
        var duration = audio.duration

        // example 12.3234 seconds
        // console.log('The duration of the song is of: ' + duration + ' seconds')
        // Alternatively, just display the integer value with
        // parseInt(duration)
        // 12 seconds
        resolve(parseInt(duration))
      },
      false
    )
  })
}

export const getBase64OfsvgURL = (srcURL) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = srcURL
    img.crossOrigin = 'Anonymous'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.height = img.naturalHeight
      canvas.width = img.naturalWidth
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL()
      resolve(dataURL)
    }
    img.onerror = (error) => reject(error)
  })
}

export const getFormatDateTime=(dateTime)=>{
  if(dateTime){
    return moment(dateTime).format("LLL");
  }else{
    return "-";
  }
}

/**
 * Get query for the datatable
 * page
 * perpage
 */
export const getPageSizeFromURL = (query, location) => {
  if (query.charAt(0) === '?') {
    // eslint-disable-line
    query = query.substring(1) // eslint-disable-line
  }
  query = querystring.parse(query)

  if (
    Object.keys(query).length > 0 &&
    ((Object.keys(query).includes('page') &&
      Object.keys(query).includes('perpage')) ||
      Object.keys(query).includes('tab'))
  ) {
    const page = location.search ? Number(query.page || 1) : 1 // eslint-disable-line
    const sizePerPage = Number(query.perpage || 10) // eslint-disable-line
    const queryParams = {
      offset: Number((page - 1) * sizePerPage), // eslint-disable-line
      limit: Number(sizePerPage) // eslint-disable-line
    }

    return { queryParams, data: { ...query, page, sizePerPage } }
  }
  return false
}

/**
 * Get query for the datatable
 * page
 * perpage
 */
export const addPageSizeInURL = (
  page,
  sizePerPage,
  history,
  addQueryPayload = {}
) => {
  if (page > 0) {
    history.push({
      search: querystring.stringify({
        page: page,
        perpage: sizePerPage,
        ...addQueryPayload
      })
    })
  }
}

export const updateQueryInURL = (history, query = {}) => {
  history.push({
    search: querystring.stringify(query)
  })
}

/**
 * Title Case
 */
export const titleCase = (str) => {
  if (str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => {
        if (word) {
          return word.replace(word[0], word[0].toUpperCase())
        }
        return word
      })
      .join(' ')
  }
  return str
}

/**
 * Filter data
 */
export const filterDataObj = (values) => {
  logger({ values })
  const filterData = {}
  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key)) {
      if (values[key] && key === 'createdAt') {
        for (let index = 0; index < values[key].length; index++) {
          const element = values[key][index]
          if (index === 0) {
            filterData.fromDate = toSendDateInApi(element)
          }
          if (index === 1) {
            filterData.toDate = toSendDateInApi(element)
          }
        }
      } else {
        if (values[key]) {
          if (values[key]) {
            filterData[key] = values[key]
          }
        }
      }
    }
  }
  const filterCount = filterDataCount(filterData)
  return {
    filterData,
    filterCount
  }
}

/**
 * Filter Count Management
 */
export const filterDataCount = (filterObj) => {
  let count = Object.keys(filterObj).length || 0
  if (
    count > 0 &&
    Object.keys(filterObj).includes('fromDate') &&
    Object.keys(filterObj).includes('toDate')
  ) {
    count -= 1
  }

  return count
}

export const getTimeDiff = (timeString) => {
  if (timeString) {
    let now = moment(new Date()); //todays date
    let end = moment(timeString); // another date

    var days = now.from(end);
    days = days.replace("in", "");
    return days.concat(" ago");
  } else {
    return "";
  }
};
/**
 * Common
 */
export const infiniteLimitForListing = 4294967295

export const acceptImageFiles = '.png, .jpg, .jpeg'

export const acceptAudioFiles = '.aac'

export const acceptVideoFiles = '.mp4'
